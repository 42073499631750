<template>
    <v-app>
      <h1 class="ma-2 ml-0">Notas Fiscais de Produtos</h1>
      <v-card v-if="invoices.length">
        <v-card-title
          class="d-flex align-center justify-space-between py-sm-2 py-6"
        >
          <v-row align-center>
            <v-text-field
              v-model="searchQuery"
              placeholder="Pesquisar por RPS e status"
              outlined
              hide-details
              dense
              class="user-search text-capitalize col-sm-6 col-10 pa-2"
            >
            </v-text-field>
            <v-tooltip bottom class="col-2">
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  color="success"
                  :content="invoices.length"
                  :value="filterApply && invoices.length > 0"
                  overlap
                >
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon
                      :color="filterApply ? 'success' : 'secondary'"
                      @click="applyFilter()"
                      >{{ icons.mdiFilter }}</v-icon
                    >
                  </v-btn>
                </v-badge>
              </template>
              <span>{{ filterApply ? "Retirar Filtro" : "Aplicar Filtro" }}</span>
            </v-tooltip>
          </v-row>
  
          <transition name="slide-fade">
            <div
              class="my-2"
              :class="{ 'w-full py-2': isMobile }"
              v-show="selectedInvoices.length != 0"
            >
              <v-btn
                color="accent"
                class="ma-sm-2 my-2 text-capitalize"
                @click="reSend(getIdsOfSelectedsInvoices())"
                :block="isMobile"
              >
                <v-icon size="17" class="me-1">{{ icons.mdiRestore }}</v-icon>
                <span>Reenviar</span>
              </v-btn>
  
              <v-btn
                color="secondary"
                class="ma-sm-2 my-2 text-capitalize"
                @click="consult(getIdsOfSelectedsInvoices())"
                :block="isMobile"
              >
                <v-icon size="17" class="me-1">{{ icons.mdiRestore }}</v-icon>
                <span>Consultar</span>
              </v-btn>
            </div>
          </transition>
  
          <div :class="{ 'w-full py-2': isMobile }">
            <v-btn
              color="success"
              class="my-2 text-capitalize"
              :block="isMobile"
              @click="newNFSE()"
            >
              <v-icon size="17" class="me-1">{{ icons.mdiPlus }}</v-icon>
              <span>Nova NFS-E</span>
            </v-btn>
          </div>
        </v-card-title>
  
        <v-data-table
          :items-per-page="25"
          :page.sync="page"
          show-select
          :search="searchQuery"
          item-key="id"
          v-model="selectedInvoices"
          :headers="tableColumns"
          :items="invoices"
          :loading="loading"
          loading-text="Carregando Notas..."
          @page-count="pageCount = $event"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, -1],
            itemsPerPageText: 'Itens por pagina',
            itemsPerPageAllText: 'Todos',
            disablePagination: true,
            nextIcon: '',
            prevIcon: '',
            pageText: '',
          }"
        >
          <template v-slot:header.data-table-select>
            <v-simple-checkbox
              :ripple="false"
              :value="selected"
              @input="selectAll"
            ></v-simple-checkbox>
          </template>
  
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              :value="isSelected"
              :ripple="false"
              :readonly="item.status == 'billed'"
              :disabled="item.status == 'billed'"
              :style="{ opacity: item.status == 'billed' ? 0.2 : 1 }"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
  
          <template v-slot:item.company_id="{ item }">{{
            item.company_id.name
          }}</template>
  
          <template v-slot:item.customer="{ item }">{{
            item.customer.name
          }}</template>
  
          <template v-slot:item.chave="{ item }">{{
            item.chave | limiter
          }}</template>
  
          <template v-slot:item.value="{ item }">
            <span style="color: green">{{ (item.value / 100) | moneyBr }}</span>
          </template>
  
          <template v-slot:item.emited_at="{ item }">{{
            item.emited_at | dateBR
          }}</template>
  
          <template v-slot:item.link="{ item }">
            <span v-if="item.link">
              <a :href="item.link" target="_blank">Link da nota</a>
            </span>
          </template>
  
          <template v-slot:item.status="{ item }">
            <v-chip
              small
              class="font-weight-semibold text-capitalize"
              :color="item.status === 'billed' ? 'success' : 'secondary'"
            >
              <div v-if="item.status === 'draft'">Rascunho</div>
              <div v-if="item.status === 'pendding'">Pendente</div>
              <div v-if="item.status === 'billed'">Faturado</div>
              <div v-if="item.status === 'cancelled'">Cancelado</div>
            </v-chip>
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
  
              <v-list>
                <v-list-item @click="openJsonDialog(item.message)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">{{ icons.mdiConsole }}</v-icon>
                    <span>Ver Logs</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="openDialogCancel(item.id)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">{{
                      icons.mdiDeleteOutline
                    }}</v-icon>
                    <span>Cancelar</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
  
          <template v-slot:no-results>
            <nothing-component></nothing-component>
          </template>
  
          <template v-slot:footer class="d-flex">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </template>
        </v-data-table>
      </v-card>
      <nothing-component v-if="!invoices.length && !loading">
        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="my-2 text-capitalize"
            :block="isMobile"
            @click="newNFSE()"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Nova NFS-E</span>
          </v-btn>
        </div>
      </nothing-component>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <outgoingInvoicesFilterComponent
        ref="filterForm"
        @filter="filter($event)"
      ></outgoingInvoicesFilterComponent>
      <outgoingInvoiceForm
        ref="form"
        @onSubmit="setInvoice($event)"
      ></outgoingInvoiceForm>
      <json-dialog ref="jsonDialog" />
      <cancel-form ref="cancelForm" />
    </v-app>
  </template>
  
  <script>
  import {
    mdiDeleteOutline,
    mdiDotsVertical,
    mdiFileDocumentOutline,
    mdiFilter,
    mdiPlus,
    mdiRestore,
    mdiConsole,
  } from "@mdi/js";
  
  import outgoingInvoicesFilterComponent from "@/components/app/filters/outgoingInvoices/outgoingInvoicesFilterComponent";
  import outgoingInvoiceForm from "@/components/outgoingInvoice/form/outgoingInvoiceForm";
  
  import NothingComponent from "@/components/nothingComponent.vue";
  import JsonDialog from "@/components/app/JsonDialog.vue";
  import CancelForm from "@/components/outgoingInvoice/form/cancelForm.vue";
  
  export default {
    name: "ProductsInvoicesScreen",
  
    components: {
      outgoingInvoicesFilterComponent,
      outgoingInvoiceForm,
      NothingComponent,
      JsonDialog,
      CancelForm,
    },
  
    data() {
      return {
        icons: {
          mdiFilter,
          mdiDeleteOutline,
          mdiDotsVertical,
          mdiFileDocumentOutline,
          mdiPlus,
          mdiRestore,
          mdiConsole,
        },
  
        searchQuery: null,
        filterApply: false,
        loading: false,
  
        invoices: [],
        filteredInvoices: [],
        selectedInvoices: [],
        selected: false,
        page: null,
        pageCount: null,
  
        tableColumns: [
          { text: "#", value: "number" },
          { text: "RPS", value: "rps" },
          { text: "Chave", value: "chave" },
          { text: "Cliente", value: "customer" },
          { text: "Valor", value: "value" },
          { text: "Data de Emissão", value: "emited_at" },
          { text: "Link", value: "link" },
          { text: "Status", value: "status" },
          { text: "Ações", value: "actions" },
        ],
  
        itemsFiltered: null,
      };
    },
  
    computed: {
      invoiceShow() {
        return this.filterApply ? this.filteredInvoices : this.invoices;
      },
    },
  
    mounted() {
      this.getInvoices();
    },
  
    methods: {
      openJsonDialog(item) {
        this.$refs.jsonDialog.open(item);
      },
  
      getInvoices() {
        this.loading = true;
        this.$http
          .index("/nota-fiscal-saida", this.itemsFiltered)
          .then((response) => {
            this.invoices = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.loading = false;
      },
  
      newNFSE() {
        this.$refs.form.open();
      },
  
      getIdsOfSelectedsInvoices() {
        var id = [];
  
        this.selectedInvoices.forEach((item) => {
          id.push(item.id);
        });
  
        return id;
      },
  
      restoreNFSE(id) {
        this.$http
          .$put("/nota-fiscal-saida", { id: id, status: "pendding" })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      reSend(nota_id) {
        this.$http
          .$post("/nota-fiscal-resend", { id: nota_id })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      consult(nota_id) {
        this.$http
          .$post("/nota-fiscal-consult", { id: nota_id })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      openDialogCancel(id) {
        this.$refs.cancelForm.open(id);
      },
  
      applyFilter() {
        if (this.filterApply) {
          this.filterApply = false;
          this.itemsFiltered = false;
          this.getInvoices();
        } else {
          this.filterApply = true;
          this.$refs.filterForm.open();
        }
      },
  
      setInvoice(event) {
        this.form = {
          customer_id: event.customer.id,
          platform: "AVULSA",
          platform_transaction: "AVULSA" + event.customer.id + event.emited_at,
          value: event.value,
          billed_at: event.emited_at,
          billed: false,
          ordered_at: event.emited_at,
          service_id: event.service,
          payment_type: "AVULSA",
          receiver_type: "CREDITO",
          status: "order",
          description: event.description
        };
  
        if (event.service == 1) {
          this.form.receiver_type = "SELLER";
        }
        if (event.service == 2) {
          this.form.receiver_type = "AFFILIATE";
        }
        if (event.service == 3) {
          this.form.receiver_type = "DROPSHIPPER";
        }
  
        this.$http
          .$post("/order", this.form)
          .then((response) => {
            const orderId = [];
  
            orderId.push(response.response.id);
  
            this.makeInvoice(response.response.id);
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      async makeInvoice(id) {
        await this.$http
          .$post("/nota-fiscal-faturar", {
            orders: id,
            billed_at: this.form.billed_at,
          })
          .then((response) => {
            this.getInvoices();
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      selectAll() {
        this.selectedInvoices = this.selected
          ? []
          : this.invoices.filter(
              (element) =>
                element.status != "billed" && element.status != "canceled"
            );
        this.selected = this.selected ? false : true;
      },
  
      filter(filter) {
        this.itemsFiltered = filter;
        this.getInvoices();
      },
    },
  };
  </script>
  
  <style>
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  </style>
  